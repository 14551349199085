
/* Web Fonts */
@font-face {
  font-family: "MontereyMedium";
  src: url("./fonts/monterey/MontereyMediumFLF.ttf");
}
@font-face {
  font-family: "Abenda";
  src: url("./fonts/abenda/Abenda.ttf");
}
@font-face {
  font-family: "Monterey";
  src: url("./fonts/monterey/MontereyFLF.ttf");
}
@font-face {
  font-family: "UbuntuMedium";
  src: url("./fonts/Ubuntu/Ubuntu-Medium.ttf");
}
@font-face {
  font-family: "UbuntuLight";
  src: url("./fonts/Ubuntu/Ubuntu-Light.ttf");
}
@font-face {
  font-family: "NunitoBold";
  src: url("./fonts/Nunito/static/Nunito-Bold.ttf");
}
@font-face {
  font-family: "NunitoLight";
  src: url("./fonts/Nunito/static/Nunito-Light.ttf");
}
@font-face {
  font-family: "NunitoMedium";
  src: url("./fonts/Nunito/static/Nunito-Medium.ttf");
}
@font-face {
  font-family: "UbuntuBold";
  src: url("./fonts/Ubuntu/Ubuntu-Bold.ttf");
}
body {
  background: #fff;
  color: #181818;
}
a {
  padding-bottom: 2px;
  transition: all 0.2s ease-in-out;
  position: relative;
}
a:before,
a:after {
  content: "";
  left: 0;
  position: absolute;
  bottom: -2px;
  width: 0px;
  height: 2px;
  margin: 5px 0 0;
  transition: all 0.2s ease-in-out;
  transition-duration: 0.75s;
  opacity: 0;
  background-color: #9B4169;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.full-width {
  width: 100%;
  display: block;
}
.card__head a:before,
.card__head a:after {
  bottom: -2px;
}
a:hover {
  color: #9B4169;
  text-decoration: none;
}
a:hover:before,
a:hover:after {
  width: 100%;
  opacity: 1;
}
h1 {
  font-family: 'NunitoMedium', sans-serif;
}
h2, h3, h4 {
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
p,
span {
  font-family: "NunitoLight", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 5px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1.2px;
}
p {
  font-size: calc(12px + 0.8vw);
}
div.container {
  width: 100%;
  max-width: 1890px;
  padding: 20px;
}
@media screen and (min-width: 1024px) {
  p,
  span {
    font-weight: 200;
    line-height: 2;
  }
}
/* Header */
.header {
  z-index: 10;
  background-color: #363636;
  width: 150px;
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.header .animated {
  padding: 20px;
}
.nav-item-desktop .is-active:before,
.nav-item-desktop .is-active:after,
.nav-item .is-active:before,
.nav-item .is-active:after {
  width: 100%;
  opacity: 1;
}
/* Navigation Menu */
.modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 1;
}
.modal-container .modal-background {
  background: #fff;
}
.modal-container .modal-background .modal {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100vh;
}
.modal-container.open {
  z-index: 10;
  transform: scale(1);
}
.modal-container.open .modal-background .modal {
  animation: blowUpModal 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container.open + .page-container {
  z-index: 1;
  animation: blowUpContent 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container.open.out .modal-background .modal {
  animation: blowUpModalTwo 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container.open.out + .page-container {
  animation: blowUpContentTwo 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes blowUpContent {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99.9% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0);
  }
}
@keyframes blowUpContentTwo {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.menu-toggle {
  cursor: pointer;
  z-index: 100;
  width: 30px;
  display: block;
  position: fixed;
  right: 50px;
  top: 30px;
}
.menu-toggle.open {
  z-index: 100;
}
.modal-open .header {
  position: unset;
}
.menu-toggle span,
.menu-toggle:after,
.menu-toggle:before {
  background-color: #383838;
  border-radius: 3px;
  content: '';
  display: block;
  height: 2px;
  margin: 7px 0;
  transition: all .2s ease-in-out;
}
.menu-toggle.open:before,
.menu-toggle.open:after,
.menu-toggle.open span {
  background-color: #f3f3f3;
}
.menu-toggle.open:before {
  transform: translateY(10px) rotate(135deg);
}
.menu-toggle.open:after {
  transform: translateY(-9px) rotate(-135deg);
}
.menu-toggle.open span {
  transform: scale(0);
}
@media screen and (min-width: 1024px) {
  .menu-toggle:hover span,
  .menu-toggle:hover:before,
  .menu-toggle:hover:after {
    background-color: #f3f3f3;
  }
}
@keyframes shake {
  from {transform: skew(10deg, 10deg)}
  to {transform:  skew(-10deg, -10deg)}
}
.nav {
  background: #363636;
  height: 100%;
  margin: auto;
  transform: translateY(50%);
  top: 50%;
}
.nav-link {
  color: #f3f3f3;
}
.nav-item {
  max-width: 200px;
  margin: 10px auto;
  text-align: center;
  font-size: 30px;
}
.close:after {
  transform: rotate(45deg);
}
.close:before {
  transform: rotate(135deg);
}
ul.nav-desktop {
  display: none;
  flex-direction: column;
  bottom: 50px;
  position: fixed;
  left: 0;
  width: 150px;
  text-align: right;
  padding: 0 10px;
}
.nav-desktop .nav-item-desktop {
  display: block;
  margin: 10px;
  font-size: 14px;
  text-align: right;
}
.resume-link span,
.nav-desktop .nav-item-desktop a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2.5px;
}
@media screen and (min-width: 650px) {
  ul.nav-desktop {
    display: flex;
  }
}

/* Home */
.home {
  height: 100vh;
}
.page-container:after {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/bubbles.png');
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: bottom left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.home .container {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin: 0;
  align-items: center;
}
.triangle {
  background-image: url('../../assets/images/triangle.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  height: calc(100vh - 40px);
}
@media screen and (min-width: 1024px) {
  .home .triangle {
    background-position: 100%;
  }
  .home .container {
    margin: 0 0 40px 0;
  }
  .home {
    padding-right: 30px;
  }
}

.home .content-right {
  height: 100%;
}
.home .content-right h1 {
  font-size: calc(30px + 2.5vw);
  text-align: right;
  text-transform: uppercase;
  padding-top: 10%;
}
.home .content-right span {
  display: block;
  font-size: 30px;
  font-weight: 400;
  text-align: right;
  padding-top: 3%;
}
.home .content-right img {
  float: right;
  max-width: 500px;
}
@media screen and (max-width: 1030px) {
  div.home .content-left .text {
    max-width: 420px;
  }
}
@media screen and (max-width: 1023px) {
  div.menu-toggle {
    right: 30px;
    top: 20px;
  }
  header.header {
    display: none;
  }
  div.triangle {
    height: 100%;
  }
  div.home .container {
    padding: 20px 30px 20px 20px;
    flex-direction: column;
  }
  div.home .content-right {
    order: 1;
  }
  div.home .content-right div {
    align-items: center;
  }
  div.home .content-right h1 {
    text-align: center;
  }
  div.home .content-left {
    order: 2;
  }
  div.home .content-left {
    width: 100%;
  }
  .home .content-right img {
    max-width: 250px;
  }
  div.home .content-right .animated:nth-child(2){
    position: relative;
    bottom: 0;
  }
  div.page-container:after {
    background-size: 200px;
    z-index: -1;
  }
  div.home .content-left .text {
    max-width: 400px;
    position: relative;
    top: 0px;
    margin-top: 0px;
    text-align: center;
    transform: none;
    float: none;
    margin: auto;
  }
  .home .content-right span{
    text-align: center;
  }
  div.home .content-left .text .border--right {
    border-right: 0;
    border-bottom: 2px solid #9B4169;
  }
  div.home .content-left .text p {
    font-weight: 500;
  }
}
.home .content-right div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: space-between;
  align-items: flex-end;
}
.home .content-right .animated:nth-child(2) {
  position: absolute;
  bottom: 40px;
}
.home .content-left {
  width: 70%;
  height: 100%;
  position: relative;
}
.home .content-left p {
  font-size: calc(12px + 0.8vw);
}
.home .content-left .text {
  max-width: 600px;
  text-align: right;
  float: right;
  top: 50%;
  position: absolute;
  right: 0;
  transform: translatey(-50%);
}
.text .border--right {
  border-right: 2px solid #9B4169;
  padding: 30px;
}

/* About me */
.about-me {
  padding: 0px;
  text-align: center;
}
.about-me .text {
  z-index: 10;
}
.about-me .triangle {
  position: relative;
  background-position: left;
  padding: 30px;
  padding-top: 60px;
  height: 100vh;
}
.about-me::after {
  background-position: top left;
}
.about-me h1 {
  font-size: calc(26px + 2.5vw);
  margin-bottom: 20px;
}
.about-me p {
  font-size: calc(12px + 0.8vw);
  font-weight: 400;
}
.about-me .social {
  display: flex;
  padding-left: 15px;
  justify-content: center;
}
.about-me .social a:before,
.about-me .social a:after {
  content: none;
}
@media screen and (min-width: 1024px) {
  .about-me {
    text-align: left;
  }
  .about-me h1 {
    padding-left: 170px;
  }
  .about-me .triangle {
    padding: 40px;
    padding-top: 60px;
  }
  .about-me > div {
    padding-left: 150px;
  }
  .about-me p {
    font-weight: 200;
  }
  .about-me .flex {
    display: flex;
    position: absolute;
    align-items: center;
    padding-left: 20%;
    padding-top: 50px;
  }
  .text.border--left {
    border-left: 2px solid #9B4169;
    padding: 30px;
  }
  .about-me .social {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    margin-right: 30px;
  }
}
/* Resume */
.resume-link {
  width: 150px;
  padding: 20px;
  text-align: right;
  font-size: 10px;
  line-height: 1;
  height: 100px;
}
.resume-link span,
.resume-link a {
  line-height: 1.5;
  color: #fff;
  font-size: 11px;
}
.resume-link:before,
.resume-link:after {
  content: none;
}
.resume .triangle {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 60px;
}
.resume.page-container:after {
  background-position: bottom right;
}
.resume h1 {
  width: 100%;
  font-size: calc(36px + 1vw);
}
.resume h3 {
  font-size: 28px;
  text-transform: capitalize;
  margin-top: 40px;
}
.resume .content-left,
.resume .content-right {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 23px;
  font-weight: 200;
  z-index: 1;
  flex-direction: column;
}
.resume .content-left .animated {
  width: 100%;
}
.resume .content-left .text {
  width: 100%;
}
.resume .content-left ul li {
  font-size: calc(12px + 0.8vw);
  letter-spacing: 1.5px;
  overflow-wrap: break-word;
  font-weight: 400;
}
.resume .content-right {
  flex-direction: column;
}
.resume ul {
  list-style-type: none;
  text-decoration: none;
  padding: 10px 0;
}
.resume ul li {
  font-family: "NunitoLight";
  padding: 5px 0;
  padding-right: 20px;
  border-bottom: 1px solid #d5d4d4;
}
.resume .button {
  display: block;
  padding: 10px 30px;
  border: 1px solid #383838;
  border-radius: 10px;
  margin-bottom: 50px;
  color: #383838;
  box-shadow: inset 0 0 0 0 #383838;
  transition: all ease 0.8s;
  text-align: center;
}
.resume .button:hover {
  box-shadow: inset 400px 0 0 0 #383838;
  color: #fff;
}
.resume .button:before,
.resume .button:after {
  content: none;
}
@media screen and (min-width: 1024px) {
  .resume .content-left, 
  .resume .content-right {
    width: 50%;
    height: 100%;
  }
  .resume .content-left ul li {
    font-weight: 200;
  }
  .resume .triangle {
    padding: 20px;
    padding-top: 80px;
    padding-left: 170px;
  }
}

/* Portfolio */
.portfolio {
  padding: 0px;
  padding-top: 60px;
  text-align: center;
}
.portfolio.triangle {
  background-position: left;
  height: 100%;
  width: 98%;
}
.portfolio h1 {
  font-size: calc(26px + 2.5vw);
}
.portfolio h3 {
  text-transform: capitalize;
  font-size: calc(16px + 1vw);
  letter-spacing: 1px;
  margin-top: 40px;
}
.portfolio p {
  padding: 0 20px;
  max-width: 100%;
  margin: auto;
  font-size: calc(12px + 0.8vw);
}
.portfolio .slick-slider {
  padding-top: 20px;
  z-index: 1;
  padding-bottom: 50px;
}
.slick-slide {
  margin-left: 5px;
}
/* .slick-list {
  padding: 0 20% 0 0 !important;
} */
.portfolio .slide-img,
.portfolio .open-source {
  overflow: hidden;
  border: 1px solid #9B4169;
  padding: 0px;
  border-radius: 5px;
  background-color: white;
}
.portfolio .open-source,
.portfolio .personal-work {
  width: 90%;
  margin: auto;
  padding: 10px;
}
.portfolio .slide-img img {
  width: 100%;
  transition: transform 0.5s ease;
  padding: 20px;
}
.portfolio .slide-img:hover img {
  transform: scale(1.2);
}
.portfolio .card__head {
  opacity: 0;
  height: 0;
  font-size: 14px;
}
.portfolio .card__head a,
.portfolio .card__head span {
  margin: 0;
  letter-spacing: 2px;
}
.portfolio .slide-img:hover {
  cursor: pointer;
}
.portfolio .slide-img:hover .card__head {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  opacity: 1;
  align-items: center;
  height: 100%;
  color: #ffffff;
  bottom: 0;
  justify-content: flex-end;
  padding: 10px;
  transition: opacity 0.5s ease-in, height 0.5s ease-in;
  text-transform: uppercase;
}
.portfolio .slide-img:hover .card__head a {
  color: #ffffff;
  margin-top: 3px;
  text-transform: uppercase;
}
.resume .content-right .animated {
  width: 100%;
}
.rotate-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
}
.rotate-icons img {
  width: 100%;
  max-width: 50px;
}
.open-source {
  width: 100%;
  padding-bottom: 100px;
  padding: 30px;
}
.open-source a {
  margin-top: 10px;
}
.open-source a::before {
  margin: 0;
}
.open-source img {
  width: 100%;
}
.open-source h5 {
  text-align: left;
}
.open-source__head {
  display: flex;
  justify-content: space-between;
}
.portfolio .cards-images {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media screen and (min-width: 650px) {
  .portfolio .cards-images {
    display: grid;
    grid-gap: 20px;
    width: 90%;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }
}
.portfolio .cards-images .slide-img {
  position: relative;
}
@media screen and (max-width: 649px) {
  .portfolio .cards-images {
    width: 100%;
  }
.portfolio .cards-images .slide-img:first-child,
.portfolio .cards-images .slide-img:nth-child(2),
.portfolio .cards-images .slide-img:nth-child(3) {
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  }
}

@media screen and (min-width: 650px) {
  .portfolio .cards-images .slide-img:first-child{
    grid-row: 1/3;
    grid-column: 2;
  }
  .resume .content-right .animated {
    width: auto;
  }
}

@media screen and (min-width: 1024px) {
  .portfolio {
    padding-left: 150px;
  }
  .portfolio .personal-work h3 {
    padding-top: 50px;
  }
  .portfolio p,
  .portfolio h3 {
    text-align: left;
    padding-left: 20px;
    margin: 0;
    max-width: 50%;
  }
  .portfolio h3 {
    padding-top: 120px;
  }
  .portfolio h1 {
    text-align: right;
    padding-right: 45px;
  }
  .portfolio .slick-slider {
    padding-top: 50px;
  }
  .rotate-icons {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 50%; 
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .rotate-icons img {
    margin: 10px;
  }
  .magento,
  .react,
  .javascript,
  .sass,
  .wordpress {
    transition: all 0.5s ease;
  }
  .magento:hover,
  .react:hover,
  .javascript:hover,
  .sass:hover,
  .wordpress:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
  .open-source {
    padding: 20px;
  }
  .open-source img {
    width: 50%;
  }
}

/* Contact */
.contact .triangle {
  padding: 20px;
  padding-top: 80px!important;
  text-align: center;
  height: 100vh;
}
@media screen and (min-width: 1024px) {
  .contact .triangle {
    padding-left: 170px!important;
  }

  .resume-extended .content-right {
    padding-left: 170px;
    text-align: left;
    padding: 20px;
  }
  .resume-extended .content-right div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .content-resume {
    max-width: 60%;
    padding: 20px;
    padding-right: 200px;
    order: 1;
  }
}

.resume-extended .content-right {
 order: 1;
}

.content-resume {
  order: 2;
  padding-top: 100px;
}

/* Ends */